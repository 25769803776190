@font-face {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 400;
	src: local("DM Sans Regular"), local("DMSans-Regular"),
		url("./dm-sans-v4-latin-regular.woff") format("woff");
}
@font-face {
	font-family: "DM Sans";
	font-style: italic;
	font-weight: 400;
	src: local("DM Sans Italic"), local("DMSans-Italic"),
		url("./dm-sans-v4-latin-italic.woff") format("woff");
}
@font-face {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	src: local("DM Sans Medium"), local("DMSans-Medium"),
		url("./dm-sans-v4-latin-500.woff") format("woff");
}
@font-face {
	font-family: "DM Sans";
	font-style: italic;
	font-weight: 500;
	src: local("DM Sans Medium Italic"), local("DMSans-MediumItalic"),
		url("./dm-sans-v4-latin-500italic.woff") format("woff");
}
@font-face {
	font-family: "DM Sans";
	font-style: italic;
	font-weight: 700;
	src: local("DM Sans Bold Italic"), local("DMSans-BoldItalic"),
		url("./dm-sans-v4-latin-700italic.woff") format("woff");
}

@font-face {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 700;
	src: local("DM Sans Bold"), local("DMSans-Bold"),
		url("./dm-sans-v4-latin-700.woff") format("woff");
}
